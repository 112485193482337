import { Component, EventEmitter, inject, Input, input, OnDestroy, Output } from "@angular/core";
import { FileResourceSimpleDto } from "../../generated/model/models";
import { Subscription } from "rxjs";
import { FileResourceService } from "../../generated/api/file-resource.service";
import { DatePipe, NgFor, NgIf } from "@angular/common";
import saveAs from "file-saver";
import { IconComponent } from "../icon/icon.component";
import { ModalService, ModalSizeEnum, ModalThemeEnum } from "../../services/modal/modal.service";
import { FileUploadModalComponent, IFileResourceUpload } from "./file-upload-modal/file-upload-modal.component";
import { FileDescriptionUpdateModalComponent } from "./file-description-update-modal/file-description-update-modal.component";

@Component({
    selector: "file-resource-list",
    standalone: true,
    imports: [NgFor, NgIf, DatePipe, IconComponent],
    templateUrl: "./file-resource-list.component.html",
    styleUrl: "./file-resource-list.component.scss",
})
export class FileResourceListComponent implements OnDestroy {
    private fileResourceService: FileResourceService = inject(FileResourceService);
    private modalService: ModalService = inject(ModalService);
    private subscriptions: Subscription[] = [];

    @Input() title: string = "Documents";
    @Input() fileResources: IHaveFileResource[];
    @Input() allowEditing: boolean = true;

    @Output() fileResourceUploaded = new EventEmitter<IFileResourceUpload>();
    @Output() fileResourceUpdated = new EventEmitter<IHaveFileResource>();
    @Output() fileResourceDeleted = new EventEmitter<IHaveFileResource>();

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => {
            if (subscription.unsubscribe) {
                subscription.unsubscribe();
            }
        });
    }

    public openFileUploadModal(): void {
        this.modalService
            .open(FileUploadModalComponent, null, { ModalSize: ModalSizeEnum.Medium, ModalTheme: ModalThemeEnum.Light, OverflowVisible: true }, {})
            .instance.result.then((result) => {
                if (result) {
                    this.fileResourceUploaded.emit(result);
                }
            });
    }

    public downloadFileResource(fileResource: FileResourceSimpleDto) {
        let downloadFileSubscription = this.fileResourceService.fileResourcesFileResourceGuidAsStringGet(fileResource.FileResourceGUID).subscribe((response) => {
            saveAs(response, `${fileResource.OriginalBaseFilename}.${fileResource.OriginalFileExtension}`);
        });

        this.subscriptions.push(downloadFileSubscription);
    }

    public openFileDescriptionUpdateModal(fileResource: IHaveFileResource): void {
        this.modalService
            .open(
                FileDescriptionUpdateModalComponent,
                null,
                { ModalSize: ModalSizeEnum.Medium, ModalTheme: ModalThemeEnum.Light, OverflowVisible: true },
                { FileResource: fileResource }
            )
            .instance.result.then((result) => {
                if (result) {
                    this.fileResourceUpdated.emit(result);
                }
            });
    }

    public deleteFileResource(fileResource: IHaveFileResource) {
        this.fileResourceDeleted.emit(fileResource);
    }
}

export interface IHaveFileResource {
    FileResource?: FileResourceSimpleDto;
    FileDescription?: string;
}
