<div class="full-width-page">
    <div class="page-header centered">
        <h1 class="page-title">
            <span>
                <icon class="page-header-icon" icon="License"></icon>
                License & Copyright
            </span>
        </h1>
    </div>

    <section class="license grid-12">
        <div class="sub-header">
            <div class="copy copy-2">
                <p>
                    The Groundwater Accounting Platform is developed using open-source software under the
                    <a href="https://www.gnu.org/licenses/agpl-3.0.en.html">GNU Affero General Public License</a> (AGPL). It can be redistributed and/or modified under the terms of
                    AGPL. Source code is available on request. Platform source code copyright &copy; Environmental Science Associates, California Water Data Consortium, and
                    Environmental Defense Fund. Groundwater Evaluation Toolbox copyright &copy; Olsson.
                </p>

                <p>All data is controlled by the participating agencies and will not be shared or utilized without their express consent or as required by law.</p>

                <p></p>
            </div>
        </div>
    </section>

    <section class="open-source grid-12 section-spacing-top">
        <h2 class="divider-header g-col-12">Open-Source Repositories</h2>
        <div class="rich-link g-col-4">
            <div class="rich-link-header">
                <icon class="page-header-icon" icon="Code"></icon>
                <h3>Groundwater Accounting Platform</h3>
            </div>

            <div class="rich-link-body copy copy-3">
                <p>
                    The Groundwater Accounting Platform is an open-source platform, which means the underlying technology is available to others to build upon or use how you
                    choose. The project is licensed under the <strong>GNU Affero General Public License v3.0 (AGPL-3.0).</strong>
                </p>
            </div>
            <!--
            <div class="rich-link-footer">
                <a href="https://github.com/sitkatech/qanat-community" class="btn btn-primary-outline btn-sm" target="blank">View the Code on Github</a>
            </div>

-->
        </div>

        <div class="rich-link g-col-4">
            <div class="rich-link-header">
                <icon class="page-header-icon" icon="Code"></icon>
                <h3>GET Engine</h3>
            </div>

            <div class="rich-link-body copy copy-3">
                <p>The Olsson GET application provides an interface for running Modflow and Modpath simulations.</p>
            </div>
            <!--
            <div class="rich-link-footer">
                <a href="https://github.com/sitkatech/GETEngine/" class="btn btn-primary-outline btn-sm" target="blank">View the Code on Github</a>
            </div>

-->
        </div>
    </section>
</div>
