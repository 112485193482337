

<ng-container *ngIf="currentUser$ | async">
    <ng-container *ngIf="geography$ | async as geography">
        <water-dashboard-nav [geography]="geography" (waterAccountCreated)="onWaterAccountCreated()"> </water-dashboard-nav>

        <div class="full-width-page">
            <page-header
                preTitle="Water Dashboard"
                pageTitle="Water Accounts"
                [customRichTextTypeID]="richTextID"
                icon="WaterAccounts"
                [templateBottomRight]="templateBottomRight"></page-header>

            <ng-template #templateBottomRight>
                <button routerLink="/water-accounts/request-changes" class="btn btn-orange request-changes-button" [disabled]="isLoading || currentUserHasNoGeographies">
                    Request Water Account Changes
                </button>
            </ng-template>

            <div class="page-body" *ngIf="currentUserGeographies$ | async as currentUserGeographies" [loadingSpinner]="{ isLoading: isLoading && firstLoad, loadingHeight: 700 }">
                <app-alert-display></app-alert-display>

                <ng-container *ngIf="!currentUserHasNoGeographies; else noWaterAccountsMessage">
                    <ng-container *ngIf="waterAccounts$ | async as waterAccounts">
                        <hybrid-map-grid [rowData]="waterAccounts" 
                                         [columnDefs]="columnDefs$ | async" 
                                         downloadFileName="water-accounts" 
                                         entityIDField="WaterAccountID"
                                         (onMapLoad)="handleMapReady($event)" 
                                         [selectedValue]="selectedWaterAccountID" 
                                         (selectedValueChange)="onSelectedWaterAccountIDChanged($event)" >                                                  
                            <div mapLayers *ngIf="mapIsReady">
                                <ng-container *ngIf="waterAccountIDs$ | async as waterAccountIDs">
                                    <ng-container *ngIf="wells$ | async as wells">
                                        <water-accounts-layer
                                            [geographyID]="geography.GeographyID"
                                            [waterAccountIDs]="waterAccountIDs"
                                            [selectedWaterAccountID]="selectedWaterAccountID"
                                            [wells]="wells"
                                            [map]="map"
                                            [layerControl]="layerControl"
                                            (layerBoundsCalculated)="handleLayerBoundsCalculated($event)"
                                            (waterAccountSelected)="onSelectedWaterAccountIDChanged($event)">
                                        </water-accounts-layer>
                                    </ng-container>
                                </ng-container>
                                <gsa-boundaries *ngIf="geography" [displayOnLoad]="false" [map]="map" [geographyID]="geography.GeographyID" [layerControl]="layerControl">
                                </gsa-boundaries>

                                <zone-group-layer
                                    *ngFor="let zoneGroup of zoneGroups$ | async"
                                    [displayOnLoad]="false"
                                    [zoneGroupID]="zoneGroup.ZoneGroupID"
                                    [zoneGroupName]="zoneGroup.ZoneGroupName"
                                    [map]="map"
                                    [layerControl]="layerControl">
                                </zone-group-layer>

                                <geography-external-map-layer
                                    *ngFor="let externalMapLayer of externalMapLayers$ | async"
                                    [map]="map"
                                    [layerControl]="layerControl"
                                    [externalMapLayer]="externalMapLayer">
                                </geography-external-map-layer>
                            </div>
                            <div headerActions>
                                <div class="form-field" class="geography-selector">
                                    <div class="field">
                                        <select name="Geography" [(ngModel)]="currentGeography" (ngModelChange)="onGeographySelected($event)" [compareWith]="compareGeography">
                                            <option *ngFor="let geography of currentUserGeographies" [ngValue]="geography">
                                                {{ geography.GeographyName }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </hybrid-map-grid>
                    </ng-container>
                </ng-container>

                <ng-template #noWaterAccountsMessage>
                    <div class="alert alert-info">
                        <div class="alert-content">
                            <i class="fa fa-info"></i>
                            Your user profile does not currently have access to any Water Accounts. Claim Water Accounts to get started or contact a water manager for your
                            geography for assistance.
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </ng-container>
</ng-container>
