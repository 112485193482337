import { Component, ComponentRef, inject, OnInit } from "@angular/core";
import { IModal, ModalService } from "src/app/shared/services/modal/modal.service";
import { ModalComponent } from "../../modal/modal.component";
import { FormGroup, FormsModule, FormControl, ReactiveFormsModule } from "@angular/forms";
import { FormFieldComponent, FormFieldType } from "../../forms/form-field/form-field.component";

@Component({
    selector: "file-description-update-modal",
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, FormFieldComponent],
    templateUrl: "./file-description-update-modal.component.html",
    styleUrl: "./file-description-update-modal.component.scss",
})
export class FileDescriptionUpdateModalComponent implements OnInit, IModal {
    FormFieldType = FormFieldType;
    private modalService: ModalService = inject(ModalService);

    public modalComponentRef: ComponentRef<ModalComponent>;
    public modalContext: any;

    public formGroup: FormGroup<FileDescriptionUpdateForm> = new FormGroup<FileDescriptionUpdateForm>({
        FileDescription: new FormControl<string>(""),
    });

    ngOnInit(): void {
        this.formGroup.setValue({
            FileDescription: this.modalContext.FileResource.FileDescription,
        });
    }

    submitFileUpdate(): void {
        this.modalContext.FileResource.FileDescription = this.formGroup.get("FileDescription").value;
        this.modalService.close(this.modalComponentRef, this.modalContext.FileResource);
    }

    close(): void {
        this.modalService.close(this.modalComponentRef, null);
    }
}

export interface FileDescriptionUpdateForm {
    FileDescription?: FormControl<string>;
}
