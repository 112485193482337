<ng-container *ngIf="currentUser$ | async as currentUser">
    <ng-container *ngIf="currentGeography$ | async">
        <water-dashboard-nav [geography]="currentGeography"></water-dashboard-nav>

        <div class="full-width-page" *ngIf="currentUser$ | async">
            <page-header pageTitle="Users" icon="Users" [templateTitleAppend]="templateTitleAppend" [customRichTextTypeID]="richTextID" preTitle="Water Dashboard">
                <ng-template #templateTitleAppend>
                    <name-tag name="Admin" color="#000" class="mb-2 name-tag-nudge"></name-tag>
                </ng-template>
            </page-header>

            <div class="page-body" *ngIf="currentUserGeographies$ | async as currentUserGeographies" [loadingSpinner]="{ isLoading: isLoading && firstLoad, loadingHeight: 700 }">
                <app-alert-display></app-alert-display>

                <ng-container *ngIf="currentUserGeographies.length > 0; else noGeographies">
                    <ng-container *ngIf="gridRef">
                        <qanat-grid-header [grid]="gridRef" [leftAlignClearFiltersButton]="true">
                            <div customGridActionsRight>
                                <div class="form-field" class="geography-selector">
                                    <div class="field">
                                        <select
                                            name="Geography"
                                            [(ngModel)]="currentGeography"
                                            (ngModelChange)="onGeographySelected(currentUser, $event)"
                                            [compareWith]="compareGeography">
                                            <option *ngFor="let geography of currentUserGeographies" [ngValue]="geography">
                                                {{ geography.GeographyName }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </qanat-grid-header>
                    </ng-container>

                    <div
                        class="grid-12 mt-3"
                        [loadingSpinner]="{
                            isLoading: isLoading && !firstLoad,
                            loadingHeight: 500,
                        }">
                        <div class="tab-nav-panel g-col-12" *ngIf="geographyUsers$ | async as geographyUsers">
                            <qanat-grid
                                height="675px"
                                [rowData]="geographyUsers"
                                [columnDefs]="columnDefs$ | async"
                                [overrideDefaultGridHeader]="true"
                                (gridReady)="gridReady($event)"
                                (gridRefReady)="gridRef = $event"
                                downloadFileName="{{ currentGeography.GeographyName.toLowerCase() }}-users"></qanat-grid>
                        </div>
                    </div>
                </ng-container>

                <ng-template #noGeographies>
                    <div class="alert alert-info">
                        <div class="alert-content">
                            <i class="fa fa-info"></i>
                            Your user profile does not currently have access to view users for this geography.
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </ng-container>
</ng-container>
