/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class ReportingPeriodUpsertDto { 
    Name: string;
    StartDate: string;
    ReadyForAccountHolders?: boolean;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface ReportingPeriodUpsertDtoForm { 
    Name: FormControl<string>;
    StartDate: FormControl<string>;
    ReadyForAccountHolders?: FormControl<boolean>;
}

export class ReportingPeriodUpsertDtoFormControls { 
    public static Name = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
                Validators.minLength(1),
            ],
        }
    );
    public static StartDate = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static ReadyForAccountHolders = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
