<div class="statistics__filter year" *ngIf="$reportingPeriodYears | async as reportingPeriodYears">
    <h5 class="statistics__filter-label">Reporting Period</h5>

    <ng-container *ngIf="reportingPeriodYears.length > 0; else noYears">
        <select name="reporting-period-years" [(ngModel)]="selectedYear" (change)="onSelectionChanged()">
            <option *ngFor="let year of reportingPeriodYears" [value]="year">
                {{ year }}
            </option>
        </select>
    </ng-container>

    <ng-template #noYears>
        <p>No Valid Reporting Period Configured for Geography</p>
    </ng-template>
</div>
